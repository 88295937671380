import "./styles.scss"

import React from "react"
import { Fade } from "react-awesome-reveal"

import { useLocale } from "context/locale"

import { FleetItem } from "./components"

import { CooperationContact } from "page_components/home/Cooperation/components"

const Cooperation = ({ pageData }) => {
  const { t } = useLocale()

  return (
    <section className="cooperation">
      <div className="container-fluid">
        <div className="cooperation__header">
          <div className="cooperation__header-image">
            <div className="cooperation__header-image-caption">
              <h3>{t("Dołącz do współpracy")}</h3>
            </div>
          </div>
        </div>
        <div className="cooperation__content">
          <div className="row">
            <div className="col-lg-6">
              <div className="cooperation__description">
                <h3>{pageData.acfCarrierZone.contentHeader}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.acfCarrierZone.contentDescription,
                  }}
                />
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="cooperation__features">
                <h4>{pageData.acfCarrierZone.featuresHeader}</h4>
                <div className="cooperation__list">
                  {pageData.acfCarrierZone.featuresList?.map((item, index) => (
                    <Fade delay={300} key={index}>
                      <h3>{item.text}</h3>
                    </Fade>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cooperation__fleet">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="cooperation__fleet-inner">
                <h4>{pageData.acfCarrierZone.fleetHeader}</h4>
                {pageData.acfCarrierZone.fleetList?.map((item, index) => (
                  <FleetItem
                    key={index}
                    header={item.header}
                    description={item.details?.map(item =>
                      item.text.replace("m3", "m<sup>3</sup")
                    )}
                    image={item.image?.sourceUrl}
                  />
                ))}
              </div>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <CooperationContact />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cooperation
