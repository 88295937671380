import "./styles.scss"

import React from "react"

const FleetItem = ({ header, description, image }) => {
  return (
    <div className="fleet-item">
      <img src={image} alt="" />
      <h3>{header}</h3>
      {description && (
        <div
          dangerouslySetInnerHTML={{
            __html: description.map((item, index) =>
              index !== 0 ? ` <span>+</span> ${item}` : item
            ).join(''),
          }}
        />
      )}
    </div>
  )
}

export default FleetItem
